import React, { useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useRouter } from "next/router";
import Link from "next/link";
import { Location } from "../../icon";
import DesktopCarousel from "./DesktopCarousel";
import ModalMessage from "components/Modals/ModalMessage";
import VehicleCardImage from "./VehicleCardImage";
import VehicleCountDown from "./VehicleCountDown";
import { setCookie } from "../../../cookie";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: "20px 20px 10px 20px",
    position: "relative",
  },
  flagContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    position: "absolute",
    top: -7,
    right: 0,
  },
  flag: {
    width: "18px",
    marginRight: 8,
  },
  location: {
    marginTop: "15px",
    paddingTop: "15px",
    borderTop: "1px solid #ddd",
    color: "#2E323C",
    "& svg": {
      marginRight: "4px",
    },
  },
  icon: {
    display: "inline-block",
    marginLeft: "-5px",
    marginRight: "10px",
  },
  title: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "5px",
    color: "#2E323C",
    maxWidth: "80%",
  },
  version: {
    textTransform: "lowercase",
    lineHeight: "28px",
    color: "#40474F",
    height: "55px",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  link: {
    textDecoration: "none",
    "&:visited": {
      color: "transparent",
    },
  },
  price: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    margin: "15px 0px",
    color: "#2E323C",
  },
  yearVehicle: {
    "& p": {
      color: "#40474F",
    },
  },
  "@media (max-width: 599px)": {
    particularTag: {
      top: "-10px",
    },
    inspectionTag: {
      top: "-10px",
    },
    flagContainer: {
      top: -10,
      right: 0,
    },
    flag: {
      width: "16px",
      marginRight: "4px",
    },
    title: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    link: {
      width: "100%",
    },
    version: {
      fontSize: "12px",
      lineHeight: "17px",
      height: "auto",
    },
    price: {
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: "16px",
      margin: "10px 0px 2px",
      lineHeight: "21px",
    },
    yearVehicle: {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#40474F",
    },
    location: {
      borderTop: "none",
      fontSize: "12px",
      padding: "0",
      margin: "0",
      "& svg": {
        width: "15px",
        height: "14px",
      },
    },
    card: {
      padding: "10px 8px",
      minHeight: "132px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
    },
    details: {
      padding: "4px 0 0 10px",
      width: "100%",
    },
    icon: {
      width: "18px",
      height: "17px",
    },
  },

  groupLabel: {
    backgroundColor: "black",
    height: "30px",
    position: "absolute",
    top: "0px",
    width: "100%",
    zIndex: "2",
    color: "#ffffff",
    textAlign: "center",
    padding: "5px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "8px 8px 0px 0px",
  },
}));

const VehicleItemCard = ({ vehicle, websocketData, isUserAuthenticated }) => {
  const router = useRouter();
  const { query } = router;
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 599px)");

  useEffect(() => {
    if (query.origem === "webmotors") {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 999);

      setCookie("cockpitWebmotors", true, {
        path: "/",
        expires: expirationDate,
      });
    }
  }, [query.origem]);

  if (websocketData.sku === vehicle.sku) {
    vehicle.price = websocketData.nextOffer;
    vehicle.ad_expiration_time = websocketData.adExpirationTime;
    vehicle.isVehicleSold = websocketData.isVehicleSold;
  }

  const imagePick = [
    "frente-passageiro",
    "frente-motorista",
    "painel-completo",
    "traseira-motorista",
  ];
  const isExclusive = vehicle.group.label !== "Revenda";
  const isPartOfLoyaltyProgram = vehicle.isExclusive;
  const npsLink = isUserAuthenticated ? `?nps=vai` : "";
  const vehicleLink = isExclusive
    ? `/veiculo/exclusivo/${vehicle.sku}${npsLink}`
    : `/veiculo/${vehicle.sku}${npsLink}`;

  const sortFunc = (a, b) =>
    imagePick.indexOf(a.type) - imagePick.indexOf(b.type);

  const images = vehicle.images
    .filter((image) => imagePick.includes(image.type))
    .sort(sortFunc);

  const isIpvaPaid =
    vehicle.ad_status === "advertised" &&
    (vehicle.principal.id === 3110 ||
      vehicle.principal.id === 54135 ||
      vehicle.principal.id === 53923 ||
      vehicle.principal.id === 19726 ||
      vehicle.principal.id === 38346);

  const lastMinutesToShow = 30;

  const isCautionInspectionApproved =
    vehicle?.cautionInspection?.status === "Aprovado com apontamento" ||
    vehicle?.cautionInspection?.status === "Aprovado"
      ? true
      : false;

  const isCautionInspectionDisapproved =
    vehicle?.cautionInspection?.status === "Reprovado" ? true : false;

  const isPrivateOwnerVehicle =
    vehicle.principal.label === "Veículos de Particular";

  const isCatalogoWebmotorsPage = router.pathname === "/catalogo-webmotors";

  if (isCatalogoWebmotorsPage) {
    vehicleLink = `${vehicleLink}?origem=webmotors`;
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      className={classes.card}
      style={{ position: "relative" }}
    >
      {vehicle.isVehicleSold ? (
        <ModalMessage
          title="Veículo Vendido!"
          message={`O veículo ${vehicle.name} acabou de ser vendido. Clique no botão abaixo para atualizar a página.`}
          onClick={router.reload}
          onClose={router.reload}
        />
      ) : null}

      {mobile && images.length ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <VehicleCardImage
              href={vehicleLink}
              sku={vehicle.sku}
              filename={images[0].filename}
              alt={images[0].title}
              hasIpvaPaid={isIpvaPaid}
            />
          </Grid>
          <Grid item>
            <VehicleCountDown
              limitDate={vehicle.ad_expiration_time}
              lastMinutesToShow={lastMinutesToShow}
              finishMessage="Oferta encerrada"
            />
          </Grid>
        </Grid>
      ) : (
        <DesktopCarousel
          href={vehicleLink}
          sku={vehicle.sku}
          imageList={images}
          hasIpvaPaid={isIpvaPaid}
        />
      )}

      <Link href={vehicleLink} passHref>
        <a className={classes.link} target="_blank" rel="noreferrer">
          <div className={classes.details}>
            <div className={classes.flagContainer}>
              {isCautionInspectionApproved && (
                <img
                  src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/approved-inspection-tag.svg"
                  className={classes.flag}
                  alt="laudo cautelar"
                />
              )}
              {isCautionInspectionDisapproved && (
                <img
                  src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/failed-inspection-tag.svg"
                  className={classes.flag}
                  alt="laudo cautelar"
                />
              )}

              {isPartOfLoyaltyProgram && (
                <img
                  src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/fidelidade.svg"
                  className={classes.flag}
                  alt="exclusivo platinum Santander"
                />
              )}

              {isPrivateOwnerVehicle && (
                <img
                  src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/particular-tag.svg"
                  className={classes.flag}
                  alt="veículos de particular"
                />
              )}
            </div>

            <Typography variant="h5" className={classes.title} gutterBottom>
              {vehicle.brand.label} {vehicle.model.label}
            </Typography>
            <Typography
              className={classes.version}
              variant="body1"
              gutterBottom
            >
              {vehicle.version.label}
            </Typography>
            {mobile ? (
              <Typography variant="body2" className={classes.yearVehicle}>
                {vehicle.manufactureYear}/{vehicle.modelYear} |{" "}
                {vehicle.inspection.mileage.value} KM
              </Typography>
            ) : null}
            <Typography variant="body1" className={classes.price}>
              <strong>
                R${" "}
                {Math.floor(parseFloat(vehicle.price)).toLocaleString("pt-br")}
              </strong>
            </Typography>
            {!mobile ? (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.yearVehicle}
                >
                  <Grid item>
                    <Typography variant="body2">
                      {vehicle.manufactureYear}/{vehicle.modelYear}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" gutterBottom>
                      {vehicle.inspection.mileage.value} KM
                    </Typography>
                  </Grid>
                </Grid>
                <VehicleCountDown
                  limitDate={vehicle.ad_expiration_time}
                  lastMinutesToShow={lastMinutesToShow}
                  finishMessage="Oferta encerrada"
                />
              </>
            ) : null}

            <div className={classes.location}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item style={{ display: "flex" }}>
                  <Location className={classes.icon} />
                </Grid>
                <Grid item>{vehicle.location.city}</Grid>
              </Grid>
            </div>
          </div>
        </a>
      </Link>
    </Paper>
  );
};
export default VehicleItemCard;
